.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


button, input, optgroup, select, textarea {
  color: #282c34;
  font-size: 16px;
  font-weight: 500;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-gray-background {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 1) 100%);
}

.bottom-gray-background {
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 1) 100%);
}

.ant-switch-checked .ant-switch-inner {
  background: #005DAA !important;
}

.ant-switch-inner {
  background: #CCCCCC !important;
}

.hide-me {
  visibility: hidden;
}

.ant-picker-dropdown .ant-btn-primary {
  background-color: #005daa !important;
}

.done-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.done-modal-msg {
  margin-top: 20px;
  color: #11C126;
  font-size: 48px;
  line-height: 56.25px;
  font-weight: 800;
}

.done-modal-reference-no {
  background-color: #F1F9FD;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.13px;
  width: 100%;
  padding: 30px;
  margin-top: 40px;
  text-align: center;
}

.done-modal-description {
  color: #005DAA;
  font-weight: 400;
  font-size: 18px;
  line-height: 32.4px;
  margin-top: 15px;
}

.done-modal-description p {
  margin-bottom: 5px;
}

.done-modal-buttons {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.done-modal-button-blue {
  background-color: #005DAA;
  border-radius: 8px;
  color: #fff;
  height: 48px;
  padding: 0 24px 0 24px;
}

.done-modal-button {
  border-radius: 8px;
  height: 48px;
  padding: 0 24px 0 24px;
  border: 1px solid #CCCCCC;
}

.react-tel-input .form-control {
  width: 100% !important;
}