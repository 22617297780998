@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;

/* Chrome, Edge, and Safari 
*::-webkit-scrollbar {
  display: none;
}
*/

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-tooltip {
  overflow: hidden;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.horizontal-slider {
  width: 100%;
  max-width: full;
  height: 50px;
}

.vertical-slider {
  height: 380px;
  width: 50px;
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: #ffffff;
  color: white;
  border-top: 2px solid #1b5daa;
  border-left: 4px solid #1b5daa;
  border-right: 4px solid #1b5daa;
  border-bottom: 2px solid #1b5daa;
  cursor: pointer;
  margin-right: 20px;
  box-sizing: border-box;
  border-radius: 8px;
}

.example-track.example-track-1 {
  background: #005daa;
  height: 22px !important;
  top: 0px !important;
  border-radius: 8px;
}

.horizontal-slider .example-track {
  background: rgba(0, 93, 170, 0.2);
  /* top: 12px; */
  height: 22px;
  /* margin-left: -6px; */
  /* margin-right: -6px; */
  border-radius: 999px;
}

.horizontal-slider .example-thumb {
  top: 1px;
  width: 40px;
  height: 20px;
  line-height: 38px;
  color: #005daa;
  outline: none !important;
  border-radius: 999px;
  z-index: 10;
}

.vertical-slider .example-thumb {
  left: 1px;
  width: 48px;
  line-height: 40px;
  height: 50px;
}

.vertical-slider .example-track {
  left: 20px;
  width: 10px;
}

.example-track-1::after {
  content: "ON";
  background: #005daa;
  display: flex;
  width: 100%;
  justify-content: center;
  color: #ffffff;
  height: 22px;
  align-items: center;
  border-radius: 999px;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #fff;
  height: 45px;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 0;
}
